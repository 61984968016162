
import { Vue, Component, Watch } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { ISalarySettings, ISalaryFilter, ISalarySearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class SalaryView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;
  showPicker = false;
  curDate = this.clinic.$store.SalarySearch.filter.year +
    (this.clinic.$store.SalarySearch.filter.month! < 10 ? '-0' : '-') +
    this.clinic.$store.SalarySearch.filter.month;

  filter: ISalaryFilter = $app.clone(this.clinic.$store.SalarySearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: ISalarySettings | null = null;

  @Watch('curDate')
  onDateCahnge() {
    if (this.curDate) {
      const dt = new Date(this.curDate + '-01')
      this.filter.month = dt.getMonth() + 1;
      this.filter.year = dt.getFullYear();
    } else {
      this.filter.month = null;
      this.filter.year = null;
    }
    this.findFirst();
  }

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Year'),
        value: 'SalaryYear',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('system.Month'),
        value: 'SalaryMonth',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('system.UserName'),
        value: 'UserName',
        align: 'left',
        width: '55%'
      },
      {
        text: $app.i18n('dplus.salary.MonthRate'),
        value: 'SalaryMonthRate',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.salary.HourRate'),
        value: 'SalaryHourRate',
        align: 'right',
        width: '10%'
      },
      {
        text: '',
        value: '',
        sortable: false,
        align: 'center',
        width: '5%'
      }
    ];
  }

  get search(): ISalarySearch {
    return this.clinic.$store.SalarySearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findSalary(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.SalarySearch, this.filter);
    this.clinic.$store.setSalarySearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: ISalarySettings) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeSalary(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async recalc(item: ISalarySettings) {
    this.processItem = true;
    try {
      await this.clinic.$store.recalcSalary(item);
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: ISalarySettings) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeSalary(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: ISalarySettings) {
    this.selectedItem = item;
    this.showItem = true;
  }

  async makeReport(item: ISalarySettings) {
    if (item) {
      const date = item.year + (item.month! < 10 ? '-0' : '-') + item.month;
      window.open(window.location.origin + '/reports?report=Salary&ReportDate=' + date + '-01&UserId=' + item.userId, '_blank');
    }
  }

  async append() {
    this.selectedItem = {
      userId: null,
      userName: null,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      feeId: null,
      monthRate: 0,
      hourRate: 0,
      workedDays: 0,
      workedHours: 0,
      afterFee: 'N',
      append: true,
      processing: false
    };
    this.showItem = true;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
